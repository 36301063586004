@import 'partials/fonts';
@import 'partials/variables';
@import 'partials/base';

// main container
#main-wrapper {
  width: 100%;
  height: auto;
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

#mobile-toggle {
  position: fixed;
  z-index: 2;
  top: 0.2em;
  left: calc(80% - 1%);
  font-size: 1.5em;
  text-transform: uppercase;
  background-color: $header-shadow;
  color: $color-white;
  border: none;
  transition: all ease-out 400ms;
  height: 50px;
  line-height: 50px;
  width: 70px;

  .fa-bars::before {
    text-align: center;
  }

  &:hover {
    background-color: $header-shadow;
    color: $color-white;
  }

  &:active,
  :focus {
    color: $headbg;
    background-color: $color-white;
  }
}

@keyframes example {
  0% {
    transform: scale(0);
  }

  25% {
    transform: scale(0.25);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.fade {
  display: block;
  animation-name: fade;
  animation-duration: 1.5s;
}

.hidden {
  display: none;
}

.nav-title {
  visibility: visible;
}

/* header */
#site-header {
  display: flex;
  // background-color: $honecolor;
  padding: 0.2em;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: space-between;
  height: 116px;
  margin: auto;

  #site-title {
    width: 74%;
    flex: 0 0 auto;

    #logo-title {
      color: $headbg;
      font-size: 1.2em;
      font-family: $fontList;
      text-align: left;
      line-height: 30px;
      margin: 0;
      padding: 0;
      // text-shadow: 1px 1px 1px $header-shadow;
      font-weight: bold;
    }

    #logo-text {
      display: block;
      float: left;
      color: $headbg;
      padding: 0;
      font-size: 0.65em;
      font-family: $fontList;
      line-height: 20px;
      text-align: left;
      margin: 0;
      // text-shadow: 2px 1px 1px $header-shadow;
    }
  }

  #logo {
    width: 25%;
    flex: 0 0 auto;
    height: auto;

    #logo-image {
      display: block;
    }
  }

  #socials {
    width: 100%;
    height: 45px;
    margin: 0;
    flex: 0 0 auto;
    padding-left: 0;

    #socials-container {
      margin: 0;
      padding: 0;
      height: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;

      .socials-container-link {
        display: block;
        width: 24%;
        height: 100%;
        margin: 0.1em;
        flex: 1 0 auto;
        font-family: FontAwesome;

        a {
          height: 100%;
          width: 100%;
          text-align: center;
          display: block;
          text-shadow: 1px 2px 1px $header-shadow;
          background-color: $headbg;
          text-decoration: none;

          .fa-twitter,
          .fa-facebook,
          .fa-google,
          .fa-instagram {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 45px;
            font-size: 1.25em;
            color: $color-white;
            transition: all 0.2s ease-in 0s;

            &:hover {
              transform: scale(1.2);
              font-weight: bold;
              text-shadow: 0 0 0 $header-shadow;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

#site-navigation {
  display: block;
  position: fixed;
  top: -365px;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 365px;
  margin: 0;
  background-color: $bgcolor;

  #site-navigation-container {
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;

    .site-navigation-link {
      height: 50px;
      list-style: none;
      width: 100%;
      display: block;

      .nav-links {
        line-height: 50px;
        margin: 0;
        font-size: 0.9em;
        text-align: center;
        display: block;
        color: $color-white;
        background-color: $headbg;
        transition: all 0.2s ease-in 0s;
        text-decoration: none;

        &:hover {
          text-shadow: none;
          font-weight: bold;
          background-color: $color-white;
          color: $headbg;
        }
      }
    }
  }
}

/* pages */
#contact,
#collections,
#home,
#recycling,
#recyclingfacts,
#tenways,
#whatcando {
  height: auto;
}

/* Page wrapper */
.page-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 98%;
  float: none;
  height: auto;
  padding: 0;
  margin-top: 0.5em;

  /* Slider */
  #slider {
    width: 100%;
    margin: 0.5em 0;
    height: 350px;

    .flexslider {
      height: 350px;
      overflow: hidden;
      padding: 0;
      margin: 0;
      font-family: $fontList;

      #slides {
        margin: 0;
        padding: 0;
        position: relative;
        height: 350px;
        .sliders {
          height: 100%;
          position: relative;
          transition: all 5s linear 1s;

          .slider-caption-image {
            display: block;
            width: auto;
          }

          .slider-caption {
            width: 100%;
            text-align: center;
            height: 100%;
            float: right;
            color: $color-white;
            background-color: $sliderbg-color;

            .slider-caption-title {
              font-size: 1.1em;
              text-transform: uppercase;
              font-weight: bold;
              color: $headbg;
              text-shadow: 1px 1px 1px $header-shadow;
              background-color: $bgcolor;
              line-height: 50px;
              margin: 1em auto;
              font-family: $fontList;
              width: 90%;
              letter-spacing: 0.05em;
            }

            .slider-caption-text {
              font-size: 0.8em;
              margin: 1em auto;
              line-height: 1.8em;
              color: $color-black;
              // background-color: $headbg;
              padding: 0.25em;
              width: 80%;
            }

            .large-button.homebg-color {
              display: block;
              color: $color-white;
              width: 80%;
              margin: 1em auto;
              background-color: $headbg;
              line-height: 70px;
              font-size: 1em;
              text-decoration: none;
              font-weight: bold;

              &:hover {
                color: $headbg;
                background-color: $color-white;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  /* Home Page Content */
  #home-page-content {
    background-color: $contentbg;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    height: auto;

    /* Home Page Content boxes */
    #home-page-cards {
      height: auto;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
      background-color: $color-white;

      .home-page-card {
        width: 100%;
        height: auto;
        margin: 0.25em;
        box-sizing: border-box;
        padding: 0.25em;
        flex: 1 0 auto;
        border: 1px solid $color-black;

        .home-page-card-title {
          text-indent: 0;
          height: auto;
          line-height: 35px;
          font-size: 1em;
          color: $colortxt;
          background-color: $bgcolor;
          text-align: center;
          margin: 0 0 0.25em;
          padding: 0;
          font-weight: bold;
          width: 100%;
          text-shadow: unset;
        }

        .home-page-card-image {
          margin: 0 0.25em 0 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: row;

          #paper-bottles,
          #flow-chart,
          #recycle-circle {
            flex: 0 1 auto;
            width: auto;
            margin: 0 auto;
          }

          .home-page-card-caption {
            margin: 0;
            padding: 0;
            flex: 0 1 auto;

            .card-caption-text {
              font-size: 0.85em;
              margin: 0.25em 0;
              padding: 0.25em;
              line-height: 1.6em;
              width: auto;
              display: block;
            }

            .card-caption-button {
              font-size: 1em;
              height: 35px;
              padding: 0;
              width: 90%;
              margin: 1em auto;
              line-height: 35px;
              text-align: center;
              background-color: $headbg;
              border: 0;
              color: $color-white;
              display: block;
              text-transform: uppercase;
              text-shadow: 1px 2px 1px $headbg;
              transition: all 0.2s ease-in 0s;
              text-decoration: none;

              &:hover {
                background-color: $color-white;
                color: $headbg;
                cursor: pointer;
                font-weight: bold;
                text-shadow: none;
              }
            }

            a[title~='wordsearch.gif'] {
              width: 100%;
            }
          }
        }
      }
    }
  }

  /* Other Pages Content */
  .content {
    background-color: $contentbg;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0.25em;
    height: auto;

    &::after {
      content: '.';
      color: $contentbg;
    }

    .content-title {
      margin: 0 auto;
      text-align: center;
      font-size: 1.05em;
      line-height: 60px;
      background-color: $honecolor;
      font-weight: bold;
      color: $color-white;
    }

    .text {
      width: 100%;
      height: auto;
      background-color: $color-white;
      padding: 0.5em;

      h3 {
        align-self: center;
        color: $colortxt;
        background-color: $bgcolor;
        margin: 0;
        text-align: center;
        font-size: 0.9em;
        line-height: 40px;
        display: block;
        clear: both;
        font-weight: bold;
      }

      p {
        padding: 0.25em;
        width: auto;
        margin: 0 0.1em 0 0;
        color: $color-black;
        line-height: 1.9em;
        font-size: 0.85em;
      }

      .recycle-page-img {
        float: left;
        display: block;
      }
    }

    #recycle-contact {
      padding: 0.5em;
      background-color: $colortxtbg;
      width: 100%;
      margin: 0;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 0.9em;
      gap: 1em;

      address {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.5em;
        line-height: 60px;
        height: auto;
      }

      .recycle-contact-link {
        font-size: 1em;
        color: $color-black;
        height: auto;
        line-height: 35px;
        &:hover {
          font-style: italic;
          font-weight: bold;
        }
      }

      p,
      address {
        flex: 0 1 auto;
        line-height: 35px;
        margin: 0;
        padding: 0;
        font-size: 0.9em;

        &::before {
          width: 35px;
          display: inline-block;
          font-size: 1.5em;
          line-height: 35px;
        }
      }

      #location {
        width: 100%;
        flex: 0 1 auto;
        p::before {
          font-family: FontAwesome;
          content: '\f015';
        }
      }

      #telephone {
        width: 100%;
        flex: 0 1 auto;
        p::before {
          font-family: FontAwesome;
          content: '\f098';
        }
      }

      #email {
        width: 100%;
        flex: 0 1 auto;
        p::before {
          font-family: FontAwesome;
          content: '\f0e0';
          font-style: normal;
        }
      }

      #links {
        width: 100%;
        flex: 0 1 auto;
        address::before {
          font-family: FontAwesome;
          content: '\f1ad';
          font-style: normal;
        }
      }

      #registration {
        width: 100%;
        flex: 0 1 auto;
        p::before {
          font-family: FontAwesome;
          content: '\f0ac';
          font-style: normal;
        }
      }

      #feedback-form-container {
        width: 100%;

        p::before {
          font-family: FontAwesome;
          content: '\f0ac';
        }
        #feedback-form {
          width: 99%;
          margin: 1em auto;
          background-color: $color-white;

          #feedback-form-fields {
            margin: 1em auto;
            padding: 1em;

            .feedback-form-item {
              width: 100%;
              padding: 0;

              label {
                width: 100%;
                font-size: 1.1em;
                line-height: 40px;
              }

              input {
                width: 100%;
                line-height: 35px;
                display: block;
              }

              textarea {
                width: 100%;
                margin: 0.5em 0 0;
                display: block;
              }

              input[type='submit'] {
                width: 30%;
                margin: 1em auto;
                line-height: 35px;
                background-color: $honecolor;
                border: 0;
                color: $color-white;
                display: block;
                font-size: 1.1em;

                &:hover {
                  background-color: $honecolor;
                  color: $color-white;
                  font-weight: bold;
                  cursor: pointer;
                }
              }

              .error,
              .notice,
              .success {
                line-height: 35px;
                display: block;
                margin-bottom: 0.5em;
                text-indent: 0.5em;
                padding: 0;
              }

              .error {
                background: $color-white;
                color: $error-border;
                border-color: $error-border;
                width: 100%;
              }

              .notice {
                background: $notice-bg;
                color: $notice-color;
                border-color: $notice-border;
                width: 100%;
              }

              .success {
                background: $success-bg;
                color: $success-color;
                border-color: $success-border;
                width: 100%;
              }
            }
          }
        }
      }
    }

    #whatcando-page {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, 1fr);
      grid-gap: 10px;

      figcaption {
        width: 100%;
        display: block;
      }

      #three-rs {
        width: 100%;
        grid-area: 1 / 1 / 2 / 2;
      }

      #different-materials {
        grid-area: 2 / 1 / 3 / 2;

        #different-materials-list {
          padding: 0;
          width: 100%;
          margin: 1em 0;
          display: block;

          .different-materials-list-item {
            list-style: none;
            text-indent: 0;
            line-height: 30px;
            display: inline-block;
            width: 100%;
            text-align: left;
            height: auto;

            &::before {
              font-family: FontAwesome;
              content: '\f1b8';
              font-style: normal;
              line-height: 30px;
              color: $headbg;
              display: block;
              padding: 0;
              text-align: center;
              font-size: 1em;
              width: 30px;
              height: auto;
              float: left;
            }

            .different-materials-list-link {
              font-size: 0.85em;
              width: 100%;
              text-align: left;
              line-height: 30px;
              color: $color-black;
              height: 100%;
              display: block;
              transition: all 0.2s ease-in 0s;
              text-decoration: none;

              &:hover {
                width: 100%;
                font-weight: bold;
                cursor: pointer;
              }
            }
          }
        }
      }

      #recycle-ettiqutte {
        grid-area: 3 / 1 / 4 / 2;
      }

      #in-rubbish-bin {
        grid-area: 4 / 1 / 5 / 2;
      }

      #where-to-recycle {
        grid-area: 5 / 1 / 6 / 2;

        .whatcando-image-right {
          width: auto;
          height: auto;
          display: none;
          margin: 0.9em;
          float: right;
        }

        #where-to-recycle-caption {
          width: 100%;
          display: block;
          float: left;
        }
      }

      .whatcando-image {
        margin: 2em auto;
        width: auto;
        height: auto;
        display: block;
      }
    }

    #recycling-facts-slides {
      height: unset;
    }

    #tenways-slides,
    #collection-slides {
      float: none;
      display: block;
      flex-flow: column;
      -ms-flex-pack: justify;
      justify-content: space-around;
    }

    .mask {
      width: 100%;
      min-height: 570px;
      position: relative;
      overflow: hidden;
      z-index: 1;
      display: inline-flex;
      flex-flow: row;
      -ms-flex-pack: flex-start;
      justify-content: flex-start;

      align-items: stretch;
      background-color: $color-white;

      .collection-slide {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0.25em 2em;
        overflow: auto;
        background: $color-white;

        .collection-slide-title {
          text-indent: 0;
          height: auto;
          line-height: 45px;
          color: $colortxt;
          background-color: $bgcolor;
          text-shadow: unset;
          margin: 0 auto;
          text-align: center;
          font-size: 0.9em;
          font-weight: bold;
          padding: 0;
        }

        /* Drop Cap */
        .collection-slider-text {
          width: auto;
          padding: 0.5em;
          color: $color-black;
          background-color: $colortxtbg;
          line-height: 1.8em;
          font-size: 0.75em;
          margin: 0;
          white-space: wrap;
        }
      }

      .recycling-facts-slide {
        padding: 0.25em 2em;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background: $color-white;
        overflow: auto;

        .recycling-facts-slide-text {
          font-size: 1em;
          line-height: 1.8em;
        }

        .recycling-facts-slide-image {
          display: block;
          margin: 2em auto;
        }

        .recycling-facts-slide-title {
          margin: 0;
          padding: 0;
          height: auto;
          display: block;
          text-align: center;
          line-height: 35px;
          font-size: 1em;
          text-shadow: unset;
          background-color: $color-white;
          color: $color-black;
        }

        .recycling-facts-slide-list {
          list-style: none;
          margin: 1em auto;
          padding: 0;
          width: 95%;

          .recycling-facts-slide-list-item {
            list-style: none;
            line-height: 30px;
            display: block;
            width: 100%;
            text-align: left;
            font-size: 0.75em;
            height: auto;
            clear: left;

            &::before {
              font-family: FontAwesome;
              content: '\f1b8';
              font-style: normal;
              line-height: 30px;
              color: $headbg;
              display: block;
              padding: 0;
              text-align: center;
              font-size: 1em;
              width: 30px;
              height: 30px;
              float: left;
              margin-right: 0.2em;
            }
          }
        }
      }

      .tenways-slide {
        position: absolute;
        width: 100%;
        min-height: 225px;
        height: auto;
        box-sizing: border-box;
        padding: 0.25em 2em;
        background: $color-white;
        overflow: auto;

        .tenways-slide-title {
          text-indent: 0;
          height: auto;
          line-height: 25px;
          font-size: 0.9em;
          color: $colortxt;
          background-color: $bgcolor;
          text-align: center;
          font-weight: bold;
          margin: 0;
          padding: 0;
          text-shadow: unset;
        }

        .tenways-slide-text {
          font-size: 0.75em;
          margin: 0 0 0.2em;
          padding: 0.5em;
          background-color: $colortxtbg;
          line-height: 1.8em;
          width: auto;
          display: block;
          white-space: wrap;
        }
      }

      .tenways-slide > img,
      .collection-slide > img {
        display: block;
        margin: 2em auto;
      }

      .recycle-facts-dots {
        text-align: center;

        &--dot {
          cursor: pointer;
        }
      }

      .active,
      .recycle-facts-dots-dot:hover {
        background-color: #717171;
      }

      .prev,
      .facts-prev,
      .tenways-prev {
        position: absolute;
        top: 50%;
        width: auto;
        margin-top: -22px;
        padding: 10px;
        color: $color-black;
        font-weight: bold;
        font-size: 20px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        border: none;
        z-index: 10;
        left: 0;
        text-decoration: none;
      }

      .next,
      .facts-next,
      .tenways-next {
        position: absolute;
        right: 0;
        top: 50%;
        width: auto;
        margin-top: -22px;
        padding: 10px;
        color: $color-black;
        font-weight: bold;
        font-size: 20px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        z-index: 10;
        text-decoration: none;
        border: none;
      }

      .facts-next:active,
      .facts-prev:active,
      .tenways-next:active,
      .tenways-prev:active,
      .prev:active,
      .next:active {
        background-color: $prev-next-button;
        color: $color-white;
        cursor: pointer;
      }

      .prev:hover,
      .next:hover,
      .facts-next:hover,
      .facts-prev:hover,
      .tenways-next:hover,
      .tenways-prev:hover {
        background-color: $prev-next-button;
        color: $color-white;
        cursor: pointer;
      }
    }
  }
}

/* Footer */
footer {
  height: 50px;
  text-align: center;
  margin-top: 10px;
  background-color: $headbg;

  #footer-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: flex-start;

    .footer-nav-links {
      height: 50px;
      width: 12%;
      margin: 0;
      flex: 1 0 auto;
      .footer-nav-link {
        line-height: 50px;
        color: $color-white;
        background-color: $headbg;
        display: block;
        font-size: 0.55em;
        text-shadow: 1px 2px 1px $header-shadow;
        text-decoration: none;
        &:hover {
          color: $headbg;
          background-color: $color-white;
          text-decoration: none;
          text-shadow: 1px 2px 1px $colorsocmed;
        }
      }
    }
  }
}

.copyr {
  color: $color-black;
  font-size: 0.65em;
  width: 100%;
  margin: 1em auto;
}

@media screen and (min-width: 1921px) {
  #mobile-toggle {
    display: none;
  }

  // main container
  #main-wrapper {
    width: 90%;
    height: auto;
    margin-top: 0.5em;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }

  nav div {
    position: absolute;
    top: 0;
    left: 2%;
    visibility: hidden;
  }

  .nav-title {
    visibility: visible;
  }

  /* header */
  #site-header {
    display: flex;
    background-color: $honecolor;
    padding: 0.2em;
    flex-flow: row wrap;
    -ms-flex-align: start;
    align-items: flex-start;

    justify-content: space-between;
    height: 100px;
    margin: auto;

    #site-title {
      width: 40%;
      flex: 0 0 auto;

      #logo-title {
        color: $color-white;
        font-size: 2em;
        font-family: $fontList;
        text-align: center;
        line-height: 60px;
        margin: 0;
        padding: 0;
        text-shadow: 1px 1px 1px $header-shadow;
      }

      #logo-text {
        display: block;
        float: left;
        color: $color-white;
        padding: 0;
        font-size: 0.9em;
        font-family: $fontList;
        line-height: 20px;
        text-align: center;
        margin: 0;
        text-shadow: 2px 1px 1px $header-shadow;
      }
    }

    #logo {
      width: 30%;

      #logo-image {
        display: block;
      }
    }

    /* social media icons */
    #socials {
      width: 30%;
      height: 45px;
      margin: 0;
      flex: 0 0 auto;
      padding-left: 3%;

      #socials-container {
        margin: 0;
        padding: 0;
        height: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;

        .socials-container-link {
          display: block;
          width: 23%;
          height: 100%;
          margin: 0.1em;
          flex: 1 0 auto;
          font-family: FontAwesome;

          a {
            height: 100%;
            width: 100%;
            text-align: center;
            display: block;
            text-shadow: 1px 2px 1px $header-shadow;
            background-color: $headbg;

            .fa-twitter,
            .fa-facebook,
            .fa-google,
            .fa-instagram {
              display: block;
              width: 100%;
              height: 100%;
              text-align: center;
              line-height: 45px;
              font-size: 1.25em;
              color: $color-white;
              transition: all 0.2s ease-in 0s;

              &:hover {
                transform: scale(1.2);
                font-weight: bold;
                text-shadow: 0 0 0 $header-shadow;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  /* javascript applies fixed header styles when page scrolls */
  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }

  #site-navigation {
    height: 40px;
    text-align: center;
    margin-top: 0.5em;
    z-index: 2;
    width: 100%;
    display: block;
    position: unset;

    #site-navigation-container {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 40px;
      width: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      gap: 0.2em;

      .site-navigation-link {
        height: 40px;
        margin-right: 0.1em;
        flex: 1 0 auto;
        width: 14%;

        .nav-links {
          line-height: 40px;
          font-size: 1em;
          height: 100%;
          text-shadow: 1px 2px 1px $header-shadow;

          &:hover {
            font-weight: bold;
            background-color: $color-white;
            color: $honecolor;
            text-shadow: none;
          }
        }
      }
    }
  }

  /* Collections Section */
  #collections {
    height: auto;
  }

  /* What you can do Section */
  #whatcando {
    height: auto;
  }

  @keyframes example {
    0% {
      transform: scale(0);
    }

    25% {
      transform: scale(0.25);
    }

    50% {
      transform: scale(0.5);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }

    to {
      opacity: 1;
    }
  }

  .fade {
    display: block;
    animation-name: fade;
    animation-duration: 1.5s;
  }

  /* Wrapper */
  .page-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
    float: none;
    min-height: 80vh;
    padding: 0;
    margin-top: 0.5em;

    /* Slider */
    #slider {
      // background-color: $honecolor;
      width: 100%;
      height: auto;
      margin: 0.5em 0;
      height: 450px;

      .flexslider {
        height: 450px;
        overflow: hidden;
        padding: 0;
        margin: 0;
        font-family: $fontList;

        #slides {
          margin: 0;
          padding: 0;
          position: relative;
          height: 450px;
          .sliders {
            height: 100%;

            .slider-caption-image {
              display: block;
              width: 20%;
              margin: 0;
            }

            .slider-caption {
              text-align: center;
              width: 50%;
              height: 100%;
              float: right;

              .slider-caption-title {
                font-size: 1.3em;
                line-height: 50px;
                font-family: $fontList;
              }

              .slider-caption-text {
                font-size: 1em;
                line-height: 1.8em;
                width: 90%;
              }

              .large-button.homebg-color {
                display: block;
                width: 35%;
                margin: 1em auto;
                font-size: 1.2em;

                &:hover {
                  color: $headbg;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    #home-page-content {
      background-color: $contentbg;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      height: auto;

      #home-page-cards {
        height: auto;
        display: flex;
        flex-flow: row;
        align-items: stretch;
        justify-content: flex-start;
        background-color: $color-white;

        .home-page-card {
          width: 31.5%;
          height: auto;

          // background-color: $honecolor;
          margin: 0.25em;
          box-sizing: border-box;
          padding: 0.25em;
          flex: 1 0 auto;

          .home-page-card-title {
            line-height: 50px;
            font-size: 1.15em;
          }

          .home-page-card-image {
            margin: 0 0.25em 0 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;

            #paper-bottles,
            #flow-chart,
            #recycle-circle {
              flex: 0 1 auto;
              width: auto;
              margin: 0 0.25em 0 0;
            }

            .home-page-card-caption {
              margin: 0;
              padding: 0;
              flex: 0 1 auto;

              .card-caption-text {
                font-size: 1em;
                margin: 0.25em 0;
                padding: 0.25em;
                line-height: 1.6em;
                width: auto;
                display: block;
              }

              .card-caption-button {
                font-size: 1em;
                height: 35px;
                padding: 0;
                width: 100%;
                margin: 0;
                line-height: 35px;
                text-align: center;
                background-color: $headbg;
                border: 0;
                color: $color-white;
                display: block;
                text-transform: uppercase;
                text-shadow: 1px 2px 1px $headbg;
                transition: all 0.2s ease-in 0s;

                &:hover {
                  background-color: $color-white;
                  color: $headbg;
                  cursor: pointer;
                  font-weight: bold;
                  text-shadow: none;
                }
              }

              a[title~='wordsearch.gif'] {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .content {
      background-color: $contentbg;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0.5em;
      height: auto;

      &::after {
        content: '.';
        color: $contentbg;
      }

      .content-title {
        margin: 0 auto;
        text-align: center;
        font-size: 1.8em;
        line-height: 60px;
        background-color: $honecolor;
        font-weight: bold;
        color: $color-white;
      }

      .text {
        width: 100%;
        height: auto;
        background-color: $color-white;
        font-size: 1em;
        padding: 0.5em;

        h3 {
          align-self: center;
          color: $colortxt;
          background-color: $bgcolor;
          text-align: center;
          font-size: 1em;
          line-height: 40px;
          margin: 0 auto;
        }

        p {
          padding: 0.25em;
          width: auto;
          margin: 0;
          color: $color-black;
          line-height: 1.7em;
          font-size: 0.9em;
        }
        .recycle-page-img {
          float: left;
          display: block;
        }
      }

      #recycle-contact {
        padding: 0.5em;
        background-color: $colortxtbg;
        width: 98%;
        margin: 0;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1em;

        address {
          display: flex;
          flex-flow: row wrap;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 0.5em;
          line-height: 60px;
          height: auto;
        }

        .recycle-contact-link {
          font-size: 1em;
          width: 100%;
          color: $color-black;
          height: auto;
          line-height: 35px;
          &:hover {
            font-style: italic;
            font-weight: bold;
          }
        }

        p,
        address {
          flex: 0 1 auto;
          line-height: 60px;
          margin: 0;
          padding: 0;
          font-size: 1.1em;
          &::before {
            width: 35px;
            display: inline-block;
            font-size: 1.5em;
            line-height: 35px;
          }
        }

        #location {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f015';
          }
        }

        #telephone {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f098';
          }
        }

        #email {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0e0';
            font-style: normal;
          }
        }

        #links {
          width: 100%;
          flex: 0 1 auto;
          address::before {
            font-family: FontAwesome;
            content: '\f1ad';
            font-style: normal;
          }
        }

        #registration {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
            font-style: normal;
          }
        }

        #feedback-form-container {
          width: 100%;

          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
          }

          #feedback-form {
            width: 99%;
            margin: 1em auto;
            background-color: $color-white;

            #feedback-form-fields {
              margin: 1em auto;
              padding: 1em;

              .feedback-form-item {
                width: 100%;
                padding: 0;

                label {
                  width: 100%;
                  font-size: 1.1em;
                  line-height: 40px;
                }

                input {
                  width: 100%;
                  line-height: 35px;
                  display: block;
                }

                textarea {
                  width: 100%;
                  margin: 0.5em 0 0;
                  display: block;
                }

                input[type='submit'] {
                  width: 20%;
                  margin: 1em auto;
                  line-height: 35px;
                  background-color: $honecolor;
                  border: 0;
                  color: $color-white;
                  display: block;
                  font-size: 1.1em;

                  &:hover {
                    background-color: $honecolor;
                    color: $color-white;
                    font-weight: bold;
                    cursor: pointer;
                  }
                }

                .error,
                .notice,
                .success {
                  line-height: 35px;
                  display: block;
                  margin-bottom: 0.5em;
                  text-indent: 0.5em;
                  padding: 0;
                }

                .error {
                  background: $color-white;
                  color: $error-border;
                  border-color: $error-border;
                  width: 100%;
                }

                .notice {
                  background: $notice-bg;
                  color: $notice-color;
                  border-color: $notice-border;
                  width: 100%;
                }

                .success {
                  background: $success-bg;
                  color: $success-color;
                  border-color: $success-border;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      #whatcando-page {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(7, 1fr);
        grid-gap: 10px;

        figure {
          border: 1px solid $color-black;
        }

        figcaption {
          width: 100%;
          display: block;
        }

        #three-rs {
          width: 100%;
          grid-area: 1 / 1 / 6 / 3;
        }

        #different-materials {
          grid-area: 1 / 3 / 6 / 5;

          #different-materials-list {
            padding: 0;
            width: 100%;
            margin: 1em 0;
            display: block;

            .different-materials-list-item {
              list-style: none;
              text-indent: 0;
              line-height: 40px;
              display: inline-block;
              width: 100%;
              text-align: left;
              height: auto;

              &::before {
                font-family: FontAwesome;
                content: '\f1b8';
                font-style: normal;
                line-height: 40px;
                color: $headbg;
                display: block;
                padding: 0;
                text-align: center;
                font-size: 1em;
                width: 40px;
                height: auto;
                float: left;
              }

              .different-materials-list-link {
                font-size: 0.9em;
                width: 100%;
                text-align: left;
                line-height: 40px;
                color: $color-black;
                height: 100%;
                display: block;
                transition: all 0.2s ease-in 0s;
                text-decoration: none;

                &:hover {
                  width: 100%;
                  font-weight: bold;
                  cursor: pointer;
                }
              }
            }
          }
        }

        #recycle-ettiqutte {
          grid-area: 1 / 5 / 6 / 7;
        }

        #in-rubbish-bin {
          grid-area: 1 / 7 / 6 / 9;
        }

        #where-to-recycle {
          grid-area: 6 / 1 / 9 / 9;

          .whatcando-image-right {
            width: auto;
            height: auto;
            display: block;
            float: right;
          }

          #where-to-recycle-caption {
            width: 100%;
            display: block;
            float: left;
          }
        }

        .whatcando-image {
          margin: 2em auto;
          width: auto;
          height: auto;
          display: block;
        }
      }

      #recycling-facts-slides,
      #tenways-slides,
      #collection-slides {
        position: relative;
        z-index: 1;
        height: auto;
      }

      .mask {
        width: 100%;
        min-height: 570px;
        position: relative;
        overflow: hidden;
        z-index: 2;
        display: inline-flex;
        flex-flow: row;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;

        align-items: stretch;
        background-color: $color-white;

        .collection-slide {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          overflow: auto;
          background: $color-white;

          .collection-slide-title {
            display: block;
            background-color: $bgcolor;
            color: $colortxt;
            height: auto;
            text-align: center;
            font-size: 1.3em;
            margin: 0.25em 0 0;
            padding: 0;
            line-height: 50px;
            text-shadow: unset;
          }

          /* Drop Cap */
          .collection-slider-text {
            padding: 0.5em;
            width: auto;
            color: $color-black;
            background-color: $colortxtbg;
            line-height: 1.8em;
            font-size: 1em;
            margin: 0;
          }
        }

        .recycling-facts-slide {
          padding: 0.25em 4em;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          background: $color-white;

          .recycling-facts-slide-text {
            font-size: 1.2em;
          }

          .recycling-facts-slide-title {
            margin: 0;
            padding: 0;
            height: auto;
            display: block;
            text-align: center;
            line-height: 50px;
            font-size: 1.2em;
            text-shadow: unset;
          }

          .recycling-facts-slide-list {
            list-style: none;
            margin: 1em auto;
            padding: 0;
            width: 95%;

            .recycling-facts-slide-list-item {
              list-style: none;
              line-height: 45px;
              display: block;
              width: 100%;
              text-align: left;
              font-size: 1em;
              height: auto;
              clear: left;

              &::before {
                font-family: FontAwesome;
                content: '\f1b8';
                font-style: normal;
                line-height: 50px;
                color: $headbg;
                display: block;
                padding: 0;
                text-align: center;
                font-size: 1.4em;
                width: 50px;
                height: 50px;
                float: left;
                margin-right: 0.3em;
              }
            }
          }
        }

        .tenways-slide {
          position: absolute;
          width: 100%;
          min-height: 225px;
          height: auto;
          margin: 0;
          padding: 0.5em 4em;
          background: $color-white;

          .tenways-slide-title {
            line-height: 45px;
            font-size: 1.2em;
          }

          .tenways-slide-text {
            font-size: 1em;
            background-color: $colortxtbg;
            line-height: 1.8em;
          }

          .tenways-slide-button {
            font-size: 0.95em;
            height: 35px;
            padding: 0;
            width: 50%;
            margin: 0;
            line-height: 35px;
            text-align: center;
            background-color: $colornav;
            border: 0;
            color: $colortxtbg;
            display: block;
            text-transform: uppercase;
            text-shadow: 1px 2px 1px $header-shadow;
            transition: all 0.2s ease-in 0s;

            &:hover {
              background-color: $colortxtbg;
              color: $colornav;
              cursor: pointer;
              width: 50%;
              text-shadow: none;
            }
          }
        }

        .tenways-slide > img,
        .collection-slide > img {
          display: block;
          margin: 2em auto;
        }

        .prev,
        .facts-prev,
        .tenways-prev {
          position: absolute;
          top: 50%;
          width: auto;
          margin-top: -22px;
          padding: 16px;
          color: $color-black;
          font-weight: bold;
          font-size: 20px;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          z-index: 10;
          left: 0;
        }

        .next,
        .facts-next,
        .tenways-next {
          position: absolute;
          right: 0;
          top: 50%;
          width: auto;
          margin-top: -22px;
          padding: 16px;
          color: $color-black;
          font-weight: bold;
          font-size: 20px;
          transition: 0.6s ease;
          border-radius: 0 3px 3px 0;
          z-index: 10;
        }

        .facts-next:active,
        .facts-prev:active,
        .tenways-next:active,
        .tenways-prev:active,
        .prev:active,
        .next:active {
          background-color: $prev-next-button;
          cursor: pointer;
        }

        .prev:hover,
        .next:hover,
        .facts-next:hover,
        .facts-prev:hover,
        .tenways-next:hover,
        .tenways-prev:hover {
          background-color: $prev-next-button;
          cursor: pointer;
        }
      }
    }
  }

  footer {
    height: 50px;
    text-align: center;
    font-size: 1em;
    margin-top: 10px;
    background-color: $headbg;

    #footer-nav {
      list-style: none;
      padding: 0;
      height: 100%;
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-flow: row;
      align-items: flex-start;

      .footer-nav-links {
        height: 50px;
        width: 12%;
        margin: 0;
        flex: 1 0 auto;
        -ms-flex-align: center;
        align-items: center;

        &:hover {
          background-color: $colortxtbg;
          color: $color-white;
        }

        .footer-nav-link {
          line-height: 50px;
          color: $color-white;
          background-color: $headbg;
          display: block;
          font-size: 1em;
          text-shadow: 1px 2px 1px $header-shadow;
          text-transform: uppercase;

          &:hover {
            color: $headbg;
            background-color: $color-white;
            text-decoration: none;
            text-shadow: 1px 2px 1px $colorsocmed;
          }
        }
      }
    }
  }

  .copyr {
    color: $color-black;
    font-size: 0.65em;
    width: 100%;
    margin: 1em auto;
  }
}

@media screen and (max-width: 1920px) and (min-width: 960px) {
  #mobile-toggle {
    display: none;
  }

  // main container
  #main-wrapper {
    width: 90%;
  }

  nav div {
    top: 0;
    left: 2%;
  }

  .nav-title {
    visibility: visible;
  }

  /* header */
  #site-header {
    display: flex;
    flex-flow: row wrap;
    -ms-flex-align: start;
    align-items: flex-start;

    justify-content: space-between;
    height: 100px;

    #site-title {
      width: 40%;
      flex: 0 0 auto;

      #logo-title {
        font-size: 1.9em;
        line-height: 50px;
        text-align: center;
      }

      #logo-text {
        float: left;
        font-size: 0.9em;
        line-height: 20px;
        text-align: center;
      }
    }

    #logo {
      width: 30%;

      #logo-image {
        display: block;
      }
    }

    /* social media icons */
    #socials {
      width: 30%;
      height: 45px;
      flex: 0 0 auto;
      padding-left: 4%;

      #socials-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;

        .socials-container-link {
          width: 23%;
          flex: 1 0 auto;

          a {
            height: 100%;
            display: block;

            .fa-twitter,
            .fa-facebook,
            .fa-google,
            .fa-instagram {
              line-height: 45px;
              font-size: 1.25em;

              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }
  }

  /* javascript applies fixed header styles when page scrolls */
  .fixed-header {
    position: fixed;
    z-index: 100;
  }

  #site-navigation {
    height: 40px;
    z-index: 2;
    position: unset;

    #site-navigation-container {
      height: 40px;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      gap: 0.2em;
      .site-navigation-link {
        height: 40px;
        flex: 1 0 auto;
        width: 14%;

        .nav-links {
          line-height: 40px;
          font-size: 0.9em;
          height: 100%;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }

  /* Collections Section */
  #collections {
    height: auto;
  }

  /* What you can do Section */
  #whatcando {
    height: auto;
  }

  .fade {
    display: block;
    animation-name: fade;
    animation-duration: 1.5s;
  }

  /* Wrapper */
  .page-wrapper {
    float: none;
    min-height: 80vh;

    /* Slider */
    #slider {
      margin: 0.5em 0;
      height: 450px;
      .flexslider {
        height: 450px;
        overflow: hidden;
        font-family: $fontList;

        #slides {
          margin: 0;
          position: relative;
          height: 450px;
          .sliders {
            height: 100%;

            .slider-caption-image {
              margin: 0;
              width: 20%;
            }

            .slider-caption {
              width: 50%;
              height: 100%;
              float: right;
              .slider-caption-title {
                font-size: 1.5em;
                line-height: 45px;
                margin: 0.5em auto;
              }

              .slider-caption-text {
                font-size: 1em;
                line-height: 1.8em;
                width: 90%;
                margin: 0.5em auto;
              }

              .large-button.homebg-color {
                width: 35%;
                margin: 0.5em auto;
                font-size: 1.2em;

                &:hover {
                  color: $headbg;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    #home-page-content {
      background-color: $contentbg;

      #home-page-cards {
        height: auto;
        display: flex;
        flex-flow: row;
        align-items: stretch;
        justify-content: flex-start;

        .home-page-card {
          width: 31.5%;
          margin: 0.25em;
          padding: 0.25em;
          flex: 1 0 auto;

          .home-page-card-title {
            line-height: 50px;
            font-size: 1.15em;
          }

          .home-page-card-image {
            margin: 0 0.25em 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;

            #paper-bottles,
            #flow-chart,
            #recycle-circle {
              flex: 0 1 auto;
              width: auto;
              margin: 0 0.25em 0 0;
            }

            .home-page-card-caption {
              margin: 0;
              padding: 0;
              flex: 0 1 auto;

              .card-caption-text {
                font-size: 1em;
              }

              .card-caption-button {
                font-size: 1em;
                height: 35px;
                line-height: 35px;

                &:hover {
                  cursor: pointer;
                  font-weight: bold;
                  text-shadow: none;
                }
              }

              a[title~='wordsearch.gif'] {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .content {
      height: auto;

      &::after {
        content: '.';
        color: $contentbg;
      }

      .content-title {
        margin: 0 auto;
        font-size: 1.5em;
        line-height: 60px;
      }

      .text {
        font-size: 1em;
        padding: 0.5em;

        h3 {
          align-self: center;
          font-size: 1.1em;
          line-height: 40px;
        }

        p {
          padding: 0.25em;
          font-size: 0.9em;
        }

        .recycle-page-img {
          float: left;
          display: block;
        }
      }

      #recycle-contact {
        padding: 0.5em;
        width: 100%;
        margin: 0;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1em;

        address {
          display: flex;
          flex-flow: row wrap;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 0.5em;
          line-height: 50px;
          height: auto;
        }

        .recycle-contact-link {
          font-size: 1em;
          height: auto;
          line-height: 35px;
          &:hover {
            font-style: italic;
            font-weight: bold;
          }
        }

        p,
        address {
          flex: 0 1 auto;
          line-height: 50px;
          margin: 0;
          padding: 0;
          font-size: 1em;

          &::before {
            width: 35px;
            display: inline-block;
            font-size: 1.5em;
            line-height: 35px;
          }
        }

        #location {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f015';
          }
        }

        #telephone {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f098';
          }
        }

        #email {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0e0';
            font-style: normal;
          }
        }

        #links {
          width: 100%;
          flex: 0 1 auto;
          address::before {
            font-family: FontAwesome;
            content: '\f1ad';
            font-style: normal;
          }
        }

        #registration {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
            font-style: normal;
          }
        }

        #feedback-form-container {
          width: 100%;

          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
          }
          #feedback-form {
            width: 99%;
            margin: 1em auto;
            background-color: $color-white;

            #feedback-form-fields {
              margin: 1em auto;
              padding: 1em;

              .feedback-form-item {
                width: 100%;

                label {
                  width: 100%;
                  font-size: 1.1em;
                  line-height: 40px;
                }

                input {
                  line-height: 35px;
                  width: 100%;
                }

                textarea {
                  margin: 0.5em 0 0;
                  width: 100%;
                }

                input[type='submit'] {
                  width: 20%;
                  margin: 1em auto;
                  line-height: 35px;
                  background-color: $honecolor;
                  border: 0;
                  color: $color-white;
                  display: block;
                  font-size: 1.1em;

                  &:hover {
                    background-color: $honecolor;
                    color: $color-white;
                    font-weight: bold;
                    cursor: pointer;
                  }
                }

                .error,
                .notice,
                .success {
                  line-height: 35px;
                  margin-bottom: 0.5em;
                  text-indent: 0.5em;
                }

                .error {
                  background: $error-bg;
                  color: $error-color;
                  border-color: $error-border;
                  width: 100%;
                }

                .notice {
                  background: $notice-bg;
                  color: $notice-color;
                  border-color: $notice-border;
                  width: 100%;
                }

                .success {
                  background: $success-bg;
                  color: $success-color;
                  border-color: $success-border;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      #whatcando-page {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(7, 1fr);
        grid-gap: 10px;

        figure {
          border: 1px solid $color-black;
        }

        figcaption {
          width: 100%;
          display: block;
        }

        #three-rs {
          width: 100%;
          grid-area: 1 / 1 / 6 / 3;
        }

        #different-materials {
          grid-area: 1 / 3 / 6 / 5;

          #different-materials-list {
            padding: 0;
            width: 100%;
            margin: 1em 0;
            display: block;

            .different-materials-list-item {
              line-height: 40px;
              display: inline-block;
              &::before {
                font-family: FontAwesome;
                content: '\f1b8';
                line-height: 40px;
                font-size: 1em;
                width: 40px;
                float: left;
              }

              .different-materials-list-link {
                font-size: 0.9em;
                line-height: 40px;

                &:hover {
                  width: 100%;
                }
              }
            }
          }
        }

        #recycle-ettiqutte {
          grid-area: 1 / 5 / 6 / 7;
        }

        #in-rubbish-bin {
          grid-area: 1 / 7 / 6 / 9;
        }

        #where-to-recycle {
          grid-area: 6 / 1 / 9 / 9;

          .whatcando-image-right {
            display: block;
            margin: 0.9em;
            float: right;
          }

          #where-to-recycle-caption {
            width: 100%;
            float: left;
          }
        }

        .whatcando-image {
          margin: 2em auto;
        }
      }

      #recycling-facts-slides,
      #tenways-slides,
      #collection-slides {
        position: relative;
        z-index: 1;
        height: auto;
      }

      .mask {
        min-height: 570px;
        position: relative;
        z-index: 2;
        display: inline-flex;
        flex-flow: row;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;

        align-items: stretch;
        background-color: $color-white;

        .collection-slide {
          z-index: 1;
          padding: 0;
          overflow: auto;
          padding: 0.25em 4em;

          .collection-slide-title {
            font-size: 1.2em;
            margin: 0.25em 0 0;
            line-height: 50px;
            text-shadow: unset;
          }

          /* Drop Cap */
          .collection-slider-text {
            padding: 0.5em;
            font-size: 1em;
          }
        }

        .recycling-facts-slide {
          padding: 0.25em 4em;
          z-index: 2;

          .recycling-facts-slide-text {
            font-size: 1.1em;
          }

          .recycling-facts-slide-title {
            line-height: 50px;
            font-size: 1.1em;
            text-shadow: unset;
          }

          .recycling-facts-slide-list {
            margin: 1em auto;
            width: 95%;

            .recycling-facts-slide-list-item {
              line-height: 40px;
              font-size: 1em;

              &::before {
                font-family: FontAwesome;
                content: '\f1b8';
                line-height: 50px;
                font-size: 1.4em;
                width: 50px;
                height: 50px;
                margin-right: 0.3em;
              }
            }
          }
        }

        .tenways-slide {
          min-height: 225px;
          margin: 0;
          padding: 0.5em 4em;

          .tenways-slide-title {
            line-height: 45px;
            font-size: 1.2em;
          }

          .tenways-slide-text {
            font-size: 1em;
            line-height: 1.8em;
          }

          .tenways-slide-button {
            font-size: 0.95em;
            height: 35px;
            width: 50%;
            line-height: 35px;

            &:hover {
              width: 50%;
              text-shadow: none;
            }
          }
        }

        .tenways-slide > img,
        .collection-slide > img {
          margin: 2em auto;
        }

        .prev,
        .facts-prev,
        .tenways-prev {
          top: 50%;
          margin-top: -22px;
          padding: 16px;
          font-size: 20px;
          left: 0;
        }

        .next,
        .facts-next,
        .tenways-next {
          right: 0;
          top: 50%;
          margin-top: -22px;
          padding: 16px;
          font-size: 20px;
          z-index: 10;
        }

        .facts-next:active,
        .facts-prev:active,
        .tenways-next:active,
        .tenways-prev:active,
        .prev:active,
        .next:active {
          background-color: $prev-next-button;
          cursor: pointer;
        }

        .prev:hover,
        .next:hover,
        .facts-next:hover,
        .facts-prev:hover,
        .tenways-next:hover,
        .tenways-prev:hover {
          background-color: $prev-next-button;
          cursor: pointer;
        }
      }
    }
  }

  footer {
    height: 50px;
    font-size: 1em;

    #footer-nav {
      width: 80%;
      display: flex;
      flex-flow: row;
      align-items: flex-start;
      margin: 0 auto;
      .footer-nav-links {
        height: 50px;
        width: 12%;
        flex: 1 0 auto;
        -ms-flex-align: center;
        align-items: center;

        &:hover {
          background-color: $colortxtbg;
          color: $color-white;
        }

        .footer-nav-link {
          line-height: 50px;
          font-size: 1em;

          &:hover {
            text-decoration: none;
            text-shadow: 1px 2px 1px $colorsocmed;
          }
        }
      }
    }
  }

  .copyr {
    font-size: 0.65em;
  }
}

@media screen and (max-width: 959px) and (min-width: 601px) {
  #mobile-toggle {
    display: none;
  }

  #main-wrapper {
    height: auto;
    width: 98%;
  }

  #site-header {
    padding: 0.25em;
    flex-flow: row wrap;
    -ms-flex-align: start;
    align-items: flex-start;

    justify-content: space-between;
    height: 100px;
    margin: auto;

    #site-title {
      width: 40%;

      #logo-title {
        font-size: 1.7em;
        line-height: 50px;
        text-align: center;
      }

      #logo-text {
        font-size: 0.9em;
        text-align: center;
      }
    }

    #logo {
      width: 30%;
    }

    #socials {
      width: 30%;
      margin: 0;
      padding-left: 5%;

      #socials-container {
        margin: 0;
        padding: 0;

        .socials-container-link {
          width: 23%;

          a {
            width: 100%;

            .fa-twitter,
            .fa-facebook,
            .fa-google,
            .fa-instagram {
              font-size: 1.15em;
            }
          }
        }
      }
    }
  }

  #site-navigation {
    margin-top: 0.5em;
    display: block;
    position: unset;
    height: 40px;

    #site-navigation-container {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 40px;
      width: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;

      .site-navigation-link {
        width: 14%;
        height: 40px;

        .nav-links {
          font-size: 0.85em;
          line-height: 40px;

          &:hover {
            text-shadow: none;
          }
        }
      }
    }
  }

  .page-wrapper {
    #slider {
      height: 450px;
      .flexslider {
        height: 450px;

        #slides {
          height: 450px;
          .sliders {
            height: 100%;

            .slider-caption-image {
              display: block;
              width: auto;
            }

            .slider-caption {
              width: 35%;
              position: relative;

              .slider-caption-title {
                font-size: 1.3em;
                line-height: 40px;
                margin: 0.5em auto;
              }

              .slider-caption-text {
                font-size: 1em;
                margin: 0.5em auto;
              }

              .large-button.homebg-color {
                width: 55%;
                margin: 0.5em auto;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    #home-page-content {
      margin: 0;
      #home-page-cards {
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-start;

        .home-page-card {
          width: 99%;

          .home-page-card-title {
            line-height: 50px;
            font-size: 1em;
          }

          .home-page-card-image {
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-flow: row;
            #paper-bottles,
            #flow-chart,
            #recycle-circle {
              width: auto;
            }

            .home-page-card-caption {
              margin: 0;
              padding: 0;

              .card-caption-text {
                font-size: 1em;
              }

              .card-caption-button {
                font-size: 1em;

                &:hover {
                  background-color: $colortxtbg;
                }
              }
            }
          }
        }
      }
    }

    .content {
      background-color: $contentbg;

      &::after {
        content: '.';
        color: $contentbg;
      }

      .content-title {
        font-size: 1.3em;
      }

      .text {
        width: 100%;

        h3 {
          align-self: center;
          font-size: 1.1em;
        }

        p {
          font-size: 1em;
        }

        .recycle-page-img {
          float: left;
          display: block;
          width: 16%;
        }
      }

      #recycle-contact {
        padding: 0.5em;
        width: 100%;
        margin: 0;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5em;

        address {
          display: flex;
          flex-flow: row wrap;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 0.5em;
          line-height: 50px;
          height: auto;
        }

        .recycle-contact-link {
          font-size: 0.9em;
          height: auto;
          line-height: 35px;
          &:hover {
            font-style: italic;
            font-weight: bold;
          }
        }

        p,
        address {
          flex: 0 1 auto;
          line-height: 50px;
          margin: 0;
          padding: 0;
          font-size: 0.9em;

          &::before {
            width: 35px;
            display: inline-block;
            font-size: 1.5em;
            line-height: 35px;
          }
        }

        #location {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f015';
          }
        }

        #telephone {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f098';
          }
        }

        #email {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0e0';
            font-style: normal;
          }
        }

        #links {
          width: 100%;
          flex: 0 1 auto;
          address::before {
            font-family: FontAwesome;
            content: '\f1ad';
            font-style: normal;
          }
        }

        #registration {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
            font-style: normal;
          }
        }

        #feedback-form-container {
          width: 100%;

          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
          }
          #feedback-form {
            width: 99%;
            margin: 1em auto;
            background-color: $color-white;

            #feedback-form-fields {
              margin: 1em auto;
              padding: 1em;

              .feedback-form-item {
                width: 100%;

                label {
                  width: 100%;
                  font-size: 1.1em;
                  line-height: 40px;
                }

                input {
                  line-height: 35px;
                  width: 100%;
                }

                textarea {
                  margin: 0.5em 0 0;
                  width: 100%;
                }

                input[type='submit'] {
                  width: 20%;
                  margin: 1em auto;
                  line-height: 35px;
                  background-color: $honecolor;
                  border: 0;
                  color: $color-white;
                  display: block;
                  font-size: 1.1em;

                  &:hover {
                    background-color: $honecolor;
                    color: $color-white;
                    font-weight: bold;
                    cursor: pointer;
                  }
                }

                .error,
                .notice,
                .success {
                  line-height: 35px;
                  margin-bottom: 0.5em;
                  text-indent: 0.5em;
                }

                .error {
                  background: $error-bg;
                  color: $error-color;
                  border-color: $error-border;
                  width: 100%;
                }

                .notice {
                  background: $notice-bg;
                  color: $notice-color;
                  border-color: $notice-border;
                  width: 100%;
                }

                .success {
                  background: $success-bg;
                  color: $success-color;
                  border-color: $success-border;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      #whatcando-page {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-gap: 10px;

        figcaption {
          width: 100%;
          display: block;
        }

        #three-rs {
          width: 100%;
          grid-area: 1 / 1 / 2 / 2;
        }

        #different-materials {
          grid-area: 2 / 1 / 3 / 2;

          #different-materials-list {
            padding: 0;
            width: 100%;
            margin: 1em 0;
            display: block;

            .different-materials-list-item {
              &::before {
                font-family: FontAwesome;
                content: '\f1b8';
                font-size: 1em;
              }

              .different-materials-list-link {
                font-size: 0.9em;

                &:hover {
                  width: 100%;
                }
              }
            }
          }
        }

        #recycle-ettiqutte {
          grid-area: 3 / 1 / 4 / 2;
        }

        #in-rubbish-bin {
          grid-area: 4 / 1 / 5 / 2;
        }

        #where-to-recycle {
          grid-area: 5 / 1 / 6 / 2;

          .whatcando-image-right {
            width: auto;
            height: auto;
            display: block;
            margin: 0.9em;
            float: right;
          }

          #where-to-recycle-caption {
            width: 100%;
            display: block;
            float: left;
          }
        }

        .whatcando-image {
          margin: 2em auto;
          width: auto;
          height: auto;
          display: block;
        }
      }

      #recycling-facts-slides,
      #tenways-slides,
      #collection-slides {
        position: relative;
        z-index: 1;
        height: auto;
      }

      .mask {
        width: 100%;

        .collection-slide {
          padding: 0.25em 2em;
          .collection-slide-title {
            font-size: 1.1em;
            line-height: 50px;
          }

          /* Drop Cap */
          .collection-slider-text {
            font-size: 0.95em;
          }
        }

        .recycling-facts-slide {
          padding: 0.25em 4em;
          .recycling-facts-slide-title {
            line-height: 50px;
            font-size: 1.1em;
          }

          .recycling-facts-slide-list {
            list-style: none;

            .recycling-facts-slide-list-item {
              font-size: 0.95em;
              line-height: 40px;

              &::before {
                font-size: 1.1em;
                margin-right: 0.2em;
                line-height: 40px;
              }
            }
          }
        }

        .tenways-slide {
          min-height: 200px;
          margin: 0;
          padding: 0.5em 4em;

          .tenways-slide-title {
            font-size: 1.1em;
            line-height: 50px;
          }

          .tenways-slide-text {
            font-size: 1em;
          }

          .tenways-slide-button {
            font-size: 1em;
            width: 100%;

            &:hover {
              width: 100%;
            }
          }
        }

        .prev,
        .facts-prev,
        .tenways-prev {
          top: 50%;
          margin-top: -22px;
          font-size: 18px;
        }

        .next,
        .facts-next,
        .tenways-next {
          right: 0;
        }

        .facts-next:active,
        .facts-prev:active,
        .tenways-next:active,
        .tenways-prev:active,
        .prev:active,
        .next:active {
          background-color: $prev-next-button;
          cursor: pointer;
        }

        .prev:hover,
        .next:hover,
        .facts-next:hover,
        .facts-prev:hover,
        .tenways-next:hover,
        .tenways-prev:hover {
          background-color: $prev-next-button;
          cursor: pointer;
        }
      }
    }
  }

  footer {
    height: 50px;

    #footer-nav {
      height: 50px;

      .footer-nav-links {
        height: 50px;
        width: 12%;
        .footer-nav-link {
          font-size: 0.8em;
          line-height: 50px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .copyr {
    width: 100%;
  }
}

// mobile

@media screen and (min-width: 481px) and (max-width: 600px) {
  #main-wrapper {
    width: 98%;
  }

  #site-header {
    padding: 0;
    flex-flow: row wrap;
    -ms-flex-align: start;
    align-items: flex-start;
    justify-content: space-between;
    height: 116px;
    margin: auto;

    #site-title {
      width: 73%;

      #logo-title {
        font-size: 1.5em;
        text-align: left;
        line-height: 45px;
      }

      #logo-text {
        font-size: 0.7em;
        text-align: left;
      }
    }

    #logo {
      width: 25%;
    }

    #socials {
      width: 100%;
      height: 35px;
      margin-top: 0.5em;
      padding-left: 0;

      #socials-container {
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: flex-end;

        .socials-container-link {
          width: 25%;
          flex: 0 1 auto;

          a {
            width: 100%;

            .fa-twitter,
            .fa-facebook,
            .fa-google,
            .fa-instagram {
              font-size: 1em;
              line-height: 35px;
            }
          }
        }
      }
    }
  }

  #mobile-toggle,
  .toggled ul {
    display: block;
  }

  #mobile-toggle {
    position: fixed;
    z-index: 2;
    top: 0.2em;
    left: calc(82% - 1%);
    font-size: 1.5em;
    text-transform: uppercase;
    background-color: $header-shadow;
    color: $color-white;
    border: none;
    transition: all ease-out 400ms;
    height: 50px;
    line-height: 50px;
    width: 100px;
    display: block;

    .fa-bars::before {
      text-align: center;
    }
  }

  .close-menu {
    top: -365px;
  }

  .open-menu {
    top: 0;
  }

  #mobile-toggle.hide {
    left: 100vw;
  }

  /* Make sure the button doesn't hide when menu is open. */
  #toggled #mobile-toggle.hide {
    bottom: 1em;
  }

  #mobile-toggle:hover,
  #mobile-toggle:focus {
    text-decoration: underline;
    border: solid 2px $color-white;
    cursor: pointer;
  }

  /* Mobile Navigation */
  #site-navigation {
    display: block;
    position: fixed;
    top: -365px;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 365px;
    margin: 0;

    #site-navigation-container {
      height: 100%;
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      width: 100%;

      .site-navigation-link {
        height: 50px;
        list-style: none;
        width: 100%;
        display: block;

        .nav-links {
          line-height: 50px;
          margin: 0;
          font-size: 1em;
          text-align: center;
          display: block;

          &:hover {
            text-shadow: none;
            font-weight: bold;
          }
        }
      }
    }
  }

  .hide {
    left: -5.5em;
  }

  .page-wrapper {
    height: auto;

    #slider {
      height: 350px;

      .flexslider {
        height: 350px;

        #slides {
          height: 350px;
          .sliders {
            height: 100%;
            .slider-caption-image {
              display: block;
              width: auto;
              float: none;
              position: absolute;
              left: 15vw;
              top: 0.25em;
            }

            .slider-caption {
              width: 100%;
              position: relative;

              .slider-caption-title {
                font-size: 1.2em;
                line-height: 45px;
              }

              .slider-caption-text {
                font-size: 0.9em;
              }

              .large-button.homebg-color {
                width: 60%;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    #home-page-content {
      margin: 0;
      #home-page-cards {
        height: auto;
        flex-flow: column;
        align-items: center;
        justify-content: flex-start;

        .home-page-card {
          width: 90%;

          .home-page-card-title {
            font-size: 1em;
            line-height: 30px;
          }

          .home-page-card-image {
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-flow: row;

            #paper-bottles,
            #flow-chart,
            #recycle-circle {
              width: auto;
            }

            .home-page-card-caption {
              margin: 0;

              .card-caption-text {
                font-size: 0.9em;
              }

              .card-caption-button {
                font-size: 1em;

                &:hover {
                  background-color: $colortxtbg;
                }
              }
            }
          }
        }
      }
    }

    .content {
      background-color: $contentbg;

      &::after {
        content: '.';
        color: $contentbg;
      }

      .content-title {
        font-size: 1.2em;
      }

      .text {
        width: 100%;

        h3 {
          align-self: center;
          font-size: 1em;
        }

        p {
          font-size: 0.9em;
        }
        .recycle-page-img {
          float: left;
          display: block;
        }
      }

      #recycle-contact {
        padding: 0.5em;
        background-color: $colortxtbg;
        width: 100%;
        margin: 0;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1em;

        address {
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 0.5em;
          line-height: 40px;
          height: auto;
        }

        .recycle-contact-link {
          font-size: 1em;
          width: 100%;
          color: $color-black;
          height: auto;
          line-height: 35px;
        }

        p,
        address {
          flex: 0 1 auto;
          line-height: 35px;
          margin: 0;
          padding: 0;
          font-size: 0.9em;

          &::before {
            width: 35px;
            display: inline-block;
            font-size: 1.5em;
            line-height: 35px;
          }
        }

        #location {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f015';
          }
        }

        #telephone {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f098';
          }
        }

        #email {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0e0';
            font-style: normal;
          }
        }

        #links {
          width: 100%;
          flex: 0 1 auto;
          address::before {
            font-family: FontAwesome;
            content: '\f1ad';
            font-style: normal;
          }
        }

        #registration {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
            font-style: normal;
          }
        }

        #feedback-form-container {
          width: 100%;

          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
          }
          #feedback-form {
            width: 99%;
            margin: 1em auto;
            background-color: $color-white;

            #feedback-form-fields {
              margin: 1em auto;
              padding: 1em;

              .feedback-form-item {
                width: 100%;

                label {
                  width: 100%;
                  font-size: 1.1em;
                  line-height: 40px;
                }

                input {
                  width: 100%;
                }

                textarea {
                  width: 100%;
                }

                input[type='submit'] {
                  width: 30%;
                  margin: 1em auto;
                  line-height: 35px;
                  background-color: $honecolor;
                  border: 0;
                  color: $color-white;
                  display: block;
                  font-size: 1.1em;

                  &:hover {
                    background-color: $honecolor;
                    color: $color-white;
                    font-weight: bold;
                    cursor: pointer;
                  }
                }

                .error,
                .notice,
                .success {
                  line-height: 35px;
                }

                .error {
                  background: $color-white;
                }

                .notice {
                  width: 100%;
                }

                .success {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      #whatcando-page {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-gap: 10px;

        figcaption {
          width: 100%;
          display: block;
        }

        #three-rs {
          width: 100%;
          grid-area: 1 / 1 / 2 / 2;
        }

        #different-materials {
          grid-area: 2 / 1 / 3 / 2;

          #different-materials-list {
            padding: 0;
            width: 100%;
            margin: 1em 0;
            display: block;
            line-height: 30px;

            .different-materials-list-item {
              &::before {
                font-family: FontAwesome;
                content: '\f1b8';
                font-size: 1em;
                line-height: 30px;
              }

              .different-materials-list-link {
                font-size: 0.9em;
                line-height: 30px;
                &:hover {
                  width: 100%;
                }
              }
            }
          }
        }

        #recycle-ettiqutte {
          grid-area: 3 / 1 / 4 / 2;
        }

        #in-rubbish-bin {
          grid-area: 4 / 1 / 5 / 2;
        }

        #where-to-recycle {
          grid-area: 5 / 1 / 6 / 2;

          .whatcando-image-right {
            width: auto;
            height: auto;
            display: block;
            margin: 0.9em;
            float: right;
          }

          #where-to-recycle-caption {
            width: 100%;
            display: block;
            float: left;
          }
        }

        .whatcando-image {
          margin: 2em auto;
          width: auto;
          height: auto;
          display: block;
        }
      }

      #recycling-facts-slides,
      #tenways-slides,
      #collection-slides {
        position: relative;
        z-index: 1;
        height: auto;
      }

      .mask {
        width: 100%;

        .collection-slide {
          padding: 0;

          .collection-slide-title {
            font-size: 0.95em;
            line-height: 50px;
          }

          /* Drop Cap */
          .collection-slider-text {
            font-size: 0.85em;
          }
        }

        .recycling-facts-slide {
          .recycling-facts-slide-title {
            line-height: 50px;
          }

          .recycling-facts-slide-list {
            list-style: none;

            .recycling-facts-slide-list-item {
              font-size: 0.85em;
              line-height: 35px;

              &::before {
                font-size: 1.3em;
                line-height: 35px;
              }
            }
          }
        }

        .tenways-slide {
          width: 99%;
          margin: 0;
          padding: 0.5em 4em;
          .tenways-slide-title {
            font-size: 0.95em;
            line-height: 40px;
          }

          .tenways-slide-text {
            font-size: 0.85em;
          }

          .tenways-slide-button {
            font-size: 1em;
            width: 100%;

            &:hover {
              width: 100%;
            }
          }
        }

        .prev,
        .facts-prev,
        .tenways-prev {
          top: 50%;
          margin-top: -22px;
          font-size: 18px;
        }

        .next,
        .facts-next,
        .tenways-next {
          right: 0;
        }

        .facts-next:active,
        .facts-prev:active,
        .tenways-next:active,
        .tenways-prev:active,
        .prev:active,
        .next:active {
          background-color: $prev-next-button;
          cursor: pointer;
        }

        .prev:hover,
        .next:hover,
        .facts-next:hover,
        .facts-prev:hover,
        .tenways-next:hover,
        .tenways-prev:hover {
          background-color: $prev-next-button;
          cursor: pointer;
        }
      }
    }
  }

  footer {
    height: 50px;
    margin-top: 10px;

    #footer-nav {
      height: 50px;
      margin: 0;
      .footer-nav-links {
        height: 50px;
        width: 12%;
        .footer-nav-link {
          font-size: 0.75em;
          line-height: 50px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .copyr {
    font-size: 0.6em;
    margin: 1.1em auto;
  }
}

@media screen and (min-width: 415px) and (max-width: 480px) {
  #main-wrapper {
    width: 98%;
  }

  #site-header {
    padding: 0;
    height: 116px;

    #site-title {
      width: 73%;

      #logo-title {
        font-size: 1.4em;
        text-align: left;
        line-height: 45px;
      }

      #logo-text {
        font-size: 0.7em;
        text-align: left;
      }
    }

    #logo {
      width: 25%;
    }

    #socials {
      width: 100%;
      margin-top: 0.5em;
      padding-left: 0;

      #socials-container {
        margin: 0;
        padding: 0;

        .socials-container-link {
          width: 24%;

          a {
            width: 100%;

            .fa-twitter,
            .fa-facebook,
            .fa-google,
            .fa-instagram {
              font-size: 0.85em;
            }
          }
        }
      }
    }
  }

  #mobile-toggle {
    position: fixed;
    z-index: 2;
    top: 0.2em;
    left: calc(82% - 1%);
    font-size: 1.5em;
    text-transform: uppercase;
    background-color: $header-shadow;
    color: $color-white;
    border: none;
    transition: all ease-out 400ms;
    height: 50px;
    line-height: 50px;
    width: 100px;

    .fa-bars::before {
      text-align: center;
    }
  }

  #mobile-toggle.hide {
    left: 100vw;
  }

  #site-navigation {
    display: block;
    position: fixed;
    top: -365px;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 365px;
    margin: 0;

    #site-navigation-container {
      height: 100%;
      align-items: center;
      justify-content: space-between;
      flex-flow: column;
      margin: 0;
      width: 100%;

      .site-navigation-link {
        height: 50px;
        list-style: none;
        width: 100%;
        display: block;

        .nav-links {
          line-height: 50px;
          margin: 0;
          font-size: 0.9em;
          text-align: center;
          display: block;

          &:hover {
            text-shadow: none;
            font-weight: bold;
          }
        }
      }
    }
  }

  #collections,
  #whatcando {
    min-height: 520px;
    height: unset;
  }

  .page-wrapper {
    height: auto;

    #slider {
      height: 350px;

      .flexslider {
        height: 350px;

        #slides {
          height: 350px;
          .sliders {
            height: 100%;
            .slider-caption-image {
              display: block;
              width: auto;
              float: none;
              position: absolute;
              left: 10vw;
              top: 0.25em;
            }

            .slider-caption {
              width: 100%;
              position: relative;

              .slider-caption-title {
                font-size: 1.1em;
                line-height: 45px;
              }

              .slider-caption-text {
                font-size: 0.85em;
              }

              .large-button.homebg-color {
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    #home-page-content {
      margin: 0;
      #home-page-cards {
        flex-flow: column;
        align-items: center;
        justify-content: flex-start;

        .home-page-card {
          width: 96%;

          .home-page-card-title {
            font-size: 1em;
            line-height: 30px;
          }

          .home-page-card-image {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: row;

            #paper-bottles,
            #flow-chart,
            #recycle-circle {
              width: auto;
              margin: 0 auto;
            }

            .home-page-card-caption {
              margin: 0;

              .card-caption-text {
                font-size: 0.85em;
              }

              .card-caption-button {
                font-size: 1em;

                &:hover {
                  background-color: $colortxtbg;
                }
              }
            }
          }
        }
      }
    }

    .content {
      background-color: $contentbg;
      min-height: 520px;

      &::after {
        content: '.';
        color: $contentbg;
      }

      .content-title {
        font-size: 1.1em;
      }

      .text {
        width: 100%;

        h3 {
          align-self: center;
          font-size: 1.05em;
        }

        p {
          font-size: 0.9em;
        }
        .recycle-page-img {
          float: left;
          display: block;
        }
      }

      #recycle-contact {
        padding: 0.5em;
        background-color: $colortxtbg;
        width: 100%;
        margin: 0;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5em;

        address {
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 1em;
          line-height: 50px;
          height: auto;
        }

        .recycle-contact-link {
          font-size: 1em;
          width: 100%;
          color: $color-black;
          height: auto;
          line-height: 35px;
        }

        p,
        address {
          flex: 0 1 auto;
          line-height: 50px;
          margin: 0;
          padding: 0;
          font-size: 0.9em;

          &::before {
            width: 35px;
            display: inline-block;
            font-size: 1.5em;
            line-height: 35px;
          }
        }

        #location {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f015';
          }
        }

        #telephone {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f098';
          }
        }

        #email {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0e0';
            font-style: normal;
          }
        }

        #links {
          width: 100%;
          flex: 0 1 auto;
          address::before {
            font-family: FontAwesome;
            content: '\f1ad';
            font-style: normal;
          }
        }

        #registration {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
            font-style: normal;
          }
        }

        #feedback-form-container {
          width: 100%;

          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
          }
          #feedback-form {
            width: 99%;
            margin: 1em auto;
            background-color: $color-white;

            #feedback-form-fields {
              margin: 1em auto;
              padding: 1em;

              .feedback-form-item {
                width: 100%;

                label {
                  width: 100%;
                  font-size: 1.1em;
                  line-height: 40px;
                }

                input {
                  width: 100%;
                }

                textarea {
                  width: 100%;
                }

                input[type='submit'] {
                  width: 30%;
                  margin: 1em auto;
                  line-height: 35px;
                  background-color: $honecolor;
                  border: 0;
                  color: $color-white;
                  display: block;
                  font-size: 1.1em;

                  &:hover {
                    background-color: $honecolor;
                    color: $color-white;
                    font-weight: bold;
                    cursor: pointer;
                  }
                }

                .error,
                .notice,
                .success {
                  line-height: 35px;
                }

                .error {
                  background: $color-white;
                }

                .notice {
                  width: 100%;
                }

                .success {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      #whatcando-page {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-gap: 10px;

        figcaption {
          width: 100%;
          display: block;
        }

        #three-rs {
          width: 100%;
          grid-area: 1 / 1 / 2 / 2;
        }

        #different-materials {
          grid-area: 2 / 1 / 3 / 2;

          #different-materials-list {
            padding: 0;
            width: 100%;
            margin: 1em 0;
            display: block;
            line-height: 30px;
            .different-materials-list-item {
              &::before {
                font-family: FontAwesome;
                content: '\f1b8';
                font-size: 1em;
                line-height: 30px;
              }

              .different-materials-list-link {
                font-size: 0.9em;
                line-height: 30px;
                &:hover {
                  width: 100%;
                }
              }
            }
          }
        }

        #recycle-ettiqutte {
          grid-area: 3 / 1 / 4 / 2;
        }

        #in-rubbish-bin {
          grid-area: 4 / 1 / 5 / 2;
        }

        #where-to-recycle {
          grid-area: 5 / 1 / 6 / 2;

          .whatcando-image-right {
            width: auto;
            height: auto;
            display: block;
            margin: 0.9em;
            float: right;
          }

          #where-to-recycle-caption {
            width: 100%;
            display: block;
            float: left;
          }
        }

        .whatcando-image {
          margin: 2em auto;
          width: auto;
          height: auto;
          display: block;
        }
      }

      #recycling-facts-slides,
      #tenways-slides,
      #collection-slides {
        position: relative;
        z-index: 1;
        height: auto;
        min-height: 520px;
      }

      .mask {
        width: 100%;
        min-height: 520px;

        .collection-slide {
          padding: 0;

          .collection-slide-title {
            font-size: 0.9em;
            line-height: 50px;
          }

          .collection-slider-text {
            font-size: 0.8em;
            padding: 0.5em;
          }
        }

        .recycling-facts-slide {
          .recycling-facts-slide-title {
            line-height: 50px;
          }

          .recycling-facts-slide-list {
            list-style: none;

            .recycling-facts-slide-list-item {
              font-size: 0.8em;
              line-height: 35px;

              &::before {
                font-size: 1.3em;
                line-height: 35px;
              }
            }
          }
        }

        .tenways-slide {
          width: 98.7%;

          .tenways-slide-title {
            font-size: 0.9em;
          }

          .tenways-slide-text {
            font-size: 0.8em;
          }

          .tenways-slide-button {
            font-size: 1em;
            width: 100%;

            &:hover {
              width: 100%;
            }
          }
        }

        .prev,
        .facts-prev,
        .tenways-prev {
          top: 50%;
          margin-top: -22px;
          font-size: 18px;
        }

        .next,
        .facts-next,
        .tenways-next {
          right: 0;
        }

        .facts-next:active,
        .facts-prev:active,
        .tenways-next:active,
        .tenways-prev:active,
        .prev:active,
        .next:active {
          background-color: $prev-next-button;
          cursor: pointer;
        }

        .prev:hover,
        .next:hover,
        .facts-next:hover,
        .facts-prev:hover,
        .tenways-next:hover,
        .tenways-prev:hover {
          background-color: $prev-next-button;
          cursor: pointer;
        }
      }
    }
  }

  footer {
    height: 50px;
    margin-top: 10px;

    #footer-nav {
      height: 50px;
      margin: 0;
      .footer-nav-links {
        height: 50px;
        width: 12%;
        .footer-nav-link {
          font-size: 0.7em;
          line-height: 50px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .copyr {
    font-size: 0.6em;
  }
}

@media screen and (min-width: 361px) and (max-width: 414px) {
  #main-wrapper {
    width: 98%;
  }

  #site-header {
    padding: 0;
    height: 116px;

    #site-title {
      width: 73%;

      #logo-title {
        font-size: 1.3em;
        line-height: 45px;
      }

      #logo-text {
        font-size: 0.65em;
      }
    }

    #logo {
      width: 25%;
    }

    #socials {
      width: 100%;
      margin-top: 0.5em;
      padding-left: 0;

      #socials-container {
        margin: 0;
        .socials-container-link {
          width: 24%;

          a {
            width: 100%;

            .fa-twitter,
            .fa-facebook,
            .fa-google,
            .fa-instagram {
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }

  #mobile-toggle {
    position: fixed;
    z-index: 2;
    top: 0.2em;
    left: calc(82% - 1%);
    font-size: 1.5em;
    text-transform: uppercase;
    background-color: $header-shadow;
    color: $color-white;
    border: none;
    transition: all ease-out 400ms;
    height: 50px;
    line-height: 50px;
    width: 70px;

    .fa-bars::before {
      text-align: center;
    }
  }

  #mobile-toggle.hide {
    left: 100vw;
  }

  #site-navigation {
    display: block;
    position: fixed;
    top: -365px;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 365px;
    margin: 0;

    #site-navigation-container {
      height: 100%;
      flex-flow: column;
      margin: 0;
      width: 100%;

      .site-navigation-link {
        height: 50px;
        list-style: none;
        width: 100%;
        display: block;

        .nav-links {
          line-height: 50px;
          font-size: 0.9em;

          &:hover {
            text-shadow: none;
            font-weight: bold;
          }
        }
      }
    }
  }

  .hide {
    left: -5.5em;
  }

  .page-wrapper {
    height: auto;

    #slider {
      height: 350px;

      .flexslider {
        height: 350px;

        #slides {
          height: 350px;
          .sliders {
            height: 100%;

            .slider-caption-image {
              display: block;
              width: auto;
              float: none;
              position: absolute;
              left: 0;
              top: 0.25em;
            }

            .slider-caption {
              width: 100%;
              position: relative;

              .slider-caption-title {
                font-size: 1.1em;
                line-height: 45px;
              }

              .slider-caption-text {
                font-size: 0.85em;
              }

              .large-button.homebg-color {
                width: 70%;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    #home-page-content {
      margin: 0;
      #home-page-cards {
        flex-flow: column;
        align-items: center;
        justify-content: flex-start;

        .home-page-card {
          width: 96%;

          .home-page-card-title {
            font-size: 1em;
            line-height: 30px;
          }

          .home-page-card-image {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: row;

            #paper-bottles,
            #flow-chart,
            #recycle-circle {
              width: auto;
              margin: 0 auto;
            }

            .home-page-card-caption {
              margin: 0;

              .card-caption-text {
                font-size: 0.85em;
              }

              .card-caption-button {
                font-size: 0.9em;

                &:hover {
                  background-color: $colortxtbg;
                }
              }
            }
          }
        }
      }
    }

    .content {
      background-color: $contentbg;

      &::after {
        content: '.';
        color: $contentbg;
      }

      .content-title {
        font-size: 1.05em;
      }

      .text {
        width: 100%;

        h3 {
          align-self: center;
          font-size: 1em;
        }

        p {
          font-size: 0.9em;
        }
        .recycle-page-img {
          float: left;
          display: block;
        }
      }

      #recycle-contact {
        padding: 0.5em;
        background-color: $colortxtbg;
        width: 100%;
        margin: 0;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5em;

        address {
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 1em;
          line-height: 40px;
          height: auto;
        }

        .recycle-contact-link {
          font-size: 1em;
          width: 100%;
          color: $color-black;
          height: auto;
          line-height: 35px;
        }

        p,
        address {
          flex: 0 1 auto;
          line-height: 40px;
          margin: 0;
          padding: 0;
          font-size: 0.85em;

          &::before {
            width: 35px;
            display: inline-block;
            font-size: 1.5em;
            line-height: 35px;
          }
        }

        #location {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f015';
          }
        }

        #telephone {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f098';
          }
        }

        #email {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0e0';
            font-style: normal;
          }
        }

        #links {
          width: 100%;
          flex: 0 1 auto;
          address::before {
            font-family: FontAwesome;
            content: '\f1ad';
            font-style: normal;
          }
        }

        #registration {
          width: 100%;
          flex: 0 1 auto;
          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
            font-style: normal;
          }
        }

        #feedback-form-container {
          width: 100%;

          p::before {
            font-family: FontAwesome;
            content: '\f0ac';
          }
          #feedback-form {
            width: 99%;
            margin: 1em auto;
            background-color: $color-white;

            #feedback-form-fields {
              margin: 1em auto;
              padding: 1em;

              .feedback-form-item {
                width: 100%;

                label {
                  width: 100%;
                  font-size: 1.1em;
                  line-height: 40px;
                }

                input {
                  width: 100%;
                }

                textarea {
                  width: 100%;
                }

                input[type='submit'] {
                  width: 30%;
                  margin: 1em auto;
                  line-height: 35px;
                  background-color: $honecolor;
                  border: 0;
                  color: $color-white;
                  display: block;
                  font-size: 1.1em;

                  &:hover {
                    background-color: $honecolor;
                    color: $color-white;
                    font-weight: bold;
                    cursor: pointer;
                  }
                }

                .error,
                .notice,
                .success {
                  line-height: 35px;
                }

                .error {
                  background: $color-white;
                }

                .notice {
                  width: 100%;
                }

                .success {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      #whatcando-page {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-gap: 10px;

        figcaption {
          width: 100%;
          display: block;
        }

        #three-rs {
          width: 100%;
          grid-area: 1 / 1 / 2 / 2;
        }

        #different-materials {
          grid-area: 2 / 1 / 3 / 2;

          #different-materials-list {
            padding: 0;
            width: 100%;
            margin: 1em 0;
            display: block;

            .different-materials-list-item {
              &::before {
                font-family: FontAwesome;
                content: '\f1b8';
                font-size: 1em;
                line-height: 30px;
              }

              .different-materials-list-link {
                font-size: 0.9em;
                line-height: 30px;
                &:hover {
                  width: 100%;
                }
              }
            }
          }
        }

        #recycle-ettiqutte {
          grid-area: 3 / 1 / 4 / 2;
        }

        #in-rubbish-bin {
          grid-area: 4 / 1 / 5 / 2;
        }

        #where-to-recycle {
          grid-area: 5 / 1 / 6 / 2;

          .whatcando-image-right {
            width: auto;
            height: auto;
            display: block;
            margin: 0.9em;
            float: right;
          }

          #where-to-recycle-caption {
            width: 100%;
            display: block;
            float: left;
          }
        }

        .whatcando-image {
          margin: 2em auto;
          width: auto;
          height: auto;
          display: block;
        }
      }

      #recycling-facts-slides {
        height: unset;
      }

      #tenways-slides,
      #collection-slides {
        float: none;
        display: block;
        flex-flow: column;
        -ms-flex-pack: justify;
        justify-content: space-around;
      }

      .mask {
        width: 100%;

        .collection-slide {
          padding: 0;

          .collection-slide-title {
            font-size: 0.9em;
            line-height: 50px;
          }

          .collection-slider-text {
            font-size: 0.8em;
          }
        }

        .recycling-facts-slide {
          padding: 0.25em 2em;

          .recycling-facts-slide-title {
            line-height: 40px;
          }

          .recycling-facts-slide-list {
            list-style: none;

            .recycling-facts-slide-list-item {
              font-size: 0.8em;
              line-height: 30px;

              &::before {
                font-size: 1.3em;
                line-height: 30px;
              }
            }
          }
        }

        .tenways-slide {
          padding: 0.25em 2em;

          .tenways-slide-title {
            font-size: 0.9em;
          }

          .tenways-slide-text {
            font-size: 0.8em;
          }

          .tenways-slide-button {
            font-size: 0.9em;
            width: 100%;

            &:hover {
              width: 100%;
            }
          }
        }

        .prev,
        .facts-prev,
        .tenways-prev {
          top: 50%;
          margin-top: -22px;
          font-size: 18px;
        }

        .next,
        .facts-next,
        .tenways-next {
          right: 0;
        }

        .facts-next:active,
        .facts-prev:active,
        .tenways-next:active,
        .tenways-prev:active,
        .prev:active,
        .next:active {
          background-color: $prev-next-button;
          cursor: pointer;
        }

        .prev:hover,
        .next:hover,
        .facts-next:hover,
        .facts-prev:hover,
        .tenways-next:hover,
        .tenways-prev:hover {
          background-color: $prev-next-button;
          cursor: pointer;
        }
      }
    }
  }

  footer {
    height: 50px;
    margin-top: 10px;

    #footer-nav {
      height: 50px;
      margin: 0;
      .footer-nav-links {
        height: 50px;
        width: 11%;

        .footer-nav-link {
          font-size: 0.65em;
          line-height: 50px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .copyr {
    font-size: 0.6em;
  }
}
