// $contenttxtbg: #3ea514;
// $formboxbg: #136899;
// $colorslidebg: #82b602;
// $colorsildertxt: #e6fbb3;

$color-black: #000000;
$color-white: #ffffff;
$bgcolor: #e6f5e3;
$colornav: #3fa63a;
$colortxtbg: #fcfbde;
$colortxt: #3a5f0b;
$colorsocmed: #ccffbb;
$colorsildertxtbg: #2c6700;
$headbg: #4f6e2d;
$honecolor: #4f6e2d;
$contentbg: #4f6e2d;
$header-shadow: #165404;
$prev-next-button: #000000;

$sliderbg-color: #c1ceb6;

$error-border: #ffffff;
$error-color: #297f10;
$error-bg: #297f10;

$success-border: #c6d880;
$success-color: #264409;
$success-bg: #e6efc2;

$notice-border: #ffd324;
$notice-color: #514721;
$notice-bg: #fff6bf;
