@import 'variables';
@import 'fonts';

/*Container*/
*,
*:before,
*:after {
  box-sizing: inherit;
}

.group:before,
.group:after {
  content: '';
  display: table;
}

.group:after {
  clear: both;
}

.box-set:before,
.box-set:after {
  content: '';
  display: table;
}

.box-set:after {
  clear: both;
}

.clearfix {
  clear: both;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-margin-top: 115px;
}

body {
  font-family: $fontList;
  margin: 0;
  line-height: 1;
  background-color: $bgcolor;
  height: auto;
}

/* remember to define focus styles! */

figure {
  margin: 0;
}

:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* tables still need 'cellspacing="0"' in the markup */

table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}

blockquote,
q {
  quotes: '' '';
}

#title,
#glyphs p {
  font-family: $fontList;
}

h1,
h2,
h3 {
  font-weight: normal;
  width: 100%;
  display: block;
}

p {
  overflow: hidden;
  font-weight: normal;
  width: 100%;
}

img {
  height: auto;
  max-width: 100%;
  border: none;
  outline: none;
}
